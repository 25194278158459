// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-policy-about-us-js": () => import("../src/pages/PaymentPolicy/about-us.js" /* webpackChunkName: "component---src-pages-payment-policy-about-us-js" */),
  "component---src-pages-payment-policy-pricing-structure-js": () => import("../src/pages/PaymentPolicy/pricing-structure.js" /* webpackChunkName: "component---src-pages-payment-policy-pricing-structure-js" */),
  "component---src-pages-payment-policy-privacy-policy-js": () => import("../src/pages/PaymentPolicy/privacy-policy.js" /* webpackChunkName: "component---src-pages-payment-policy-privacy-policy-js" */),
  "component---src-pages-payment-policy-refund-and-cancellation-js": () => import("../src/pages/PaymentPolicy/refund-and-cancellation.js" /* webpackChunkName: "component---src-pages-payment-policy-refund-and-cancellation-js" */),
  "component---src-pages-payment-policy-terms-and-conditions-js": () => import("../src/pages/PaymentPolicy/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-payment-policy-terms-and-conditions-js" */)
}

